import { renderMonth } from '@/utils/time';
import { theme } from '@/utils/theme';
import { type RevenueReport } from '@witmetrics/api-client';

export function formatReport(
  currentReport: RevenueReport,
  previousReport: RevenueReport
) {
  return {
    series: [
      {
        name: '2025',
        data: currentReport.data.map((d) => d.leads.treatmentAccepted),
      },
      {
        name: '2024',
        data: previousReport.data.map((d) => d.leads.treatmentAccepted),
      },
    ],
    options: {
      colors: [theme.palette['purple-3'].main, theme.palette['blue-2'].main],
      dataLabels: {
        enabled: true,
      },
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: { show: false },
      },
      plotOptions: {
        line: {
          borderRadius: 2,
        },
      },
      xaxis: {
        categories: previousReport.data.map((d) => renderMonth(d.startDate)),
      },
    },
  };
}
