import { twMerge } from 'tailwind-merge';
import { type RevenueReport } from '@witmetrics/api-client';
import OverviewCard from '@/components/OverviewCard';
import Table, { TableBody, TableCell, TableRow } from '@/components/Table';
import { usePracticeCurrency } from '@/store/useStore';
import { formatNumber, renderPrice } from '@/utils/strings';
import SalesTableHead from './SalesTableHead';
import {
  classes,
  exportReport,
  formatTreatmentLabel,
  groupTreatmentByMonth,
  styles,
  sumValues,
  sumValuesByMonth,
} from './utils';

export type TreatmentRevenueCardProps = {
  isFetchingData?: boolean;
  report: RevenueReport | null;
};

export default function TreatmentRevenueCard({
  isFetchingData,
  report,
}: TreatmentRevenueCardProps) {
  const currency = usePracticeCurrency();
  const groupedData = groupTreatmentByMonth(report);
  const totalRevenue = sumValuesByMonth(groupedData, 'revenue');
  const totalCount = sumValuesByMonth(groupedData, 'count');

  const renderRevenueRows = () => {
    return groupedData.map((treatment) => (
      <TableRow key={treatment.id} hover>
        <TableCell className={classes.stickyCell}>
          {formatTreatmentLabel(treatment)}
        </TableCell>
        {treatment.revenue.map((revenue, i) => (
          <TableCell key={i} className={classes.cell}>
            {revenue === 0 ? '--' : renderPrice(revenue, currency?.value)}
          </TableCell>
        ))}
        <TableCell className={classes.summaryCell}>
          {renderPrice(sumValues(treatment.revenue), currency?.value)}
        </TableCell>
      </TableRow>
    ));
  };

  const renderCountRows = () => {
    return groupedData.map((treatment) => (
      <TableRow key={treatment.id} hover>
        <TableCell className={classes.stickyCell}>
          {formatTreatmentLabel(treatment)}
        </TableCell>
        {treatment.count.map((count, i) => (
          <TableCell key={i} className={classes.cell}>
            {count === 0 ? '--' : formatNumber(count)}
          </TableCell>
        ))}
        <TableCell className={classes.summaryCell}>
          {formatNumber(sumValues(treatment.count))}
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <OverviewCard
      isFetchingData={isFetchingData}
      title="Treatments Overview"
      subtitle="Treatment performance per month"
      onExport={() => exportReport(report)}>
      {report && (
        <Table stickyHeader className={classes.table}>
          <SalesTableHead year={2025} />
          <TableBody sx={styles.tableBody}>
            {renderRevenueRows()}
            <TableRow hover>
              <TableCell
                className={twMerge(classes.summaryCell, classes.stickyCell)}>
                Total
              </TableCell>
              {totalRevenue.map((revenue, i) => (
                <TableCell key={i} className={classes.summaryCell}>
                  {revenue === 0 ? '--' : renderPrice(revenue, currency?.value)}
                </TableCell>
              ))}
              <TableCell className={classes.summaryCell}>
                {renderPrice(sumValues(totalRevenue), currency?.value)}
              </TableCell>
            </TableRow>
            <SalesTableHead row year={2025} />
            {renderCountRows()}
            <TableRow hover>
              <TableCell
                className={twMerge(classes.summaryCell, classes.stickyCell)}>
                Total
              </TableCell>
              {totalCount.map((count, i) => (
                <TableCell key={i} className={classes.summaryCell}>
                  {count === 0 ? '--' : formatNumber(count)}
                </TableCell>
              ))}
              <TableCell className={classes.summaryCell}>
                {formatNumber(sumValues(totalCount))}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </OverviewCard>
  );
}
